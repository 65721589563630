import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import { InputNumber } from 'element-ui';

export function getFormDefinition(vue) {
  return {
    id: {
      type: MDinput,
      props: {
        readonly: true
      }
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'dossier.status',
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    sequence: {
      type: InputNumber,
      props: {
        caption: 'common.sequence'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
